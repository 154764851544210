import '../CSS File/CarouselComponent.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import Spinner from "../element/BePatient";

import { RiArrowRightDoubleLine } from "react-icons/ri";
import SubscriptionCard from './SubscriptionCard';
import toast from 'react-hot-toast';
import { useLocation } from "react-router-dom";

const SubscriptionPlans = ({ isOpen, onClose }) => {  // Fixed props destructuring
  const [activeIndex, setActiveIndex] = useState(0);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const userId = localStorage.getItem("logged_user_id");
  const location = useLocation();
  const { postalCode } = location.state || {};

  // Memoized fetch functions
  const fetchSubscriptionData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_plan`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            postal_code: postalCode || localStorage.getItem('postalCode'),
          }),
        }
      );
      const responseData = await response.json();
      setPricingOptions(responseData.data || []);
    } catch (error) {
      console.error('Error fetching subscription data:', error);
    } finally {
      setLoading(false);
    }
  }, [postalCode]);

  const checkSubscription = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: localStorage.getItem('logged_user_id'),
            postal_code: postalCode || localStorage.getItem("postalCode")
          }),
        }
      );
      const res_json = await response.json();
      if (res_json.status) {
        setCurrentPlan(res_json);
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
    }
  }, [postalCode]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchSubscriptionData();
      if (userId) await checkSubscription();
    };
    fetchData();
  }, [fetchSubscriptionData, checkSubscription, userId]);

  useEffect(() => {
    if (pricingOptions.length > 0) {
      // const currentPlanIndex = pricingOptions.findIndex(option => 
      //   option.price === (planPrice || currentPlan.price)
      // );
      // const currentPlanIndex = pricingOptions.findIndex(option => 
      //   option.default_plan === '1'
      // );
      console.log("Pricing Options:", pricingOptions);
      console.log("Current Plan:", currentPlan);
      
      // Find the current plan index
      const currentPlanIndex = pricingOptions.findIndex(option => {
        console.log("Checking:", option); // Logs each plan being checked
        return option.subscription_plan_id === currentPlan.plan_id && currentPlan.plan_status;
      });
      
      console.log("Current Plan Index:", currentPlanIndex);
      
      // If not found, use the default plan index
      const finalIndex = currentPlanIndex !== -1 
        ? currentPlanIndex 
        : pricingOptions.findIndex(option => option.default_plan === "1");
      
      console.log("Final Selected Plan Index:", finalIndex);
      console.log("Final Selected Plan:", pricingOptions[finalIndex] || "No Plan Found");
      
      setActiveIndex(finalIndex >= 0 ? finalIndex : 0);
      
    }

  }, [currentPlan, pricingOptions]);

  const handlePlanSelection = async (id, isDefault) => {
    debugger
    if (!userId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/fetch_plan_details`,
          {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ plan_id: id }),
          }
        );
  
        const responseData = await response.json();
  
        if (responseData.status) {
          localStorage.setItem("chef_status", responseData.chef_status);
          localStorage.setItem("status", responseData.status);
          localStorage.setItem("plan_status", responseData.plan_status);
          localStorage.setItem("plan_id", responseData.plan_id);
          localStorage.setItem("plan_name", responseData.plan_name);
          localStorage.setItem("price", responseData.price);
          navigate('/add-Address-details');
        } else {
          toast.error('Please login before subscribing, then apply Subscription Plan');
        }
  
        return; // Stop further execution since user is not logged in
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        toast.error('Error fetching subscription data');
        return;
      }
    }
  
    // If user is logged in, proceed with adding the subscription plan
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/add_subscription_plan`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ plan_id: id, user_id: userId }),
        }
      );
  
      const responseData = await response.json();
  
      if (responseData.status) {

        localStorage.setItem("plan_status", isDefault ? "false" : "true");
        navigate(responseData.regular_plan ? '/chef-list' : '/add-Address-details');
      } else {
        toast.error(responseData.message || 'Subscription failed');
      }
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error('Error processing subscription');
    }
  };
  

  const settings = {
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: Math.min(3, pricingOptions.length),
    focusOnSelect: true,
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false
        }
      }
    ]
  };

  return (
    <>
      <div className="container">
        {loading ? (
          <div className="loader">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="App">
            <Link
              to={
                localStorage.getItem("logged_user_id") ? "/chef-list" : "/sign-up"
              }
              style={{ textDecoration: "none" }}
            >
              <div className="skip mt-0">
                skip
                <span>
                  <RiArrowRightDoubleLine />
                </span>
              </div>
            </Link>

            <p style={{ color: "red", padding:'15px' }}>
              Pricing is as per the Service Address Zip Code
            </p>
            <div className="pricing-container">
              {pricingOptions.map((option, index) => (
                <SubscriptionCard
                  key={option.plan_id}
                  activeIndex={activeIndex}
                  index={index}
                  type={option.plan_name}
                  price={option.price}
                  features={option.description}
                  buttonText={option.button_text}
                  // popular={option.populor}
                  onSubscribe={() =>
                    handlePlanSelection(
                      option.subscription_plan_id,
                      option.default_plan
                    )
                  }
                  changeCard={() => setActiveIndex(index)}
                />
              ))}
            </div>
            <div
              className="cancel-subscription-plan text-center mt-5"
              style={{
                color: "#FF5E41",
                display: currentPlan.plan_status ? "block" : "none",
              }}
            >
              Cancel Subscription Anytime
            </div>
          </div>
        )}

        <div className="mobile">
          <Link
            to={
              localStorage.getItem("logged_user_id") ? "/chef-list" : "/sign-up"
            }
            style={{ textDecoration: "none" }}
          >
            <div className="skip mt-0">
              skip
              <span>
                <RiArrowRightDoubleLine />
              </span>
            </div>
          </Link>

          <p style={{ color: "red" }}>
            Pricing is as per the Service Address Zip Code
          </p>

          {pricingOptions.map((option, index) => (
            <div className="div my-3">
              <div
                key={option.subscription_plan_id}
                className={`mobile-card ${
                  index === activeIndex ? "active" : ""
                }`}
                onClick={() => setActiveIndex(index)}
              >
                {/* {option.popular && <div className="popular-badge">Popular</div>} */}
                <div className="card-header-mobile text-center">
                  <h3>{option.plan_name}</h3>
                  <div className="price">${option.price}/booking</div>
                </div>

                <ul
                  className="card-features"
                  style={{ listStyleType: "disc", paddingLeft: "20px" }}
                >
                  {option.description.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
                <div className="row text-center">
                  <button
                    className="card-button-mobile"
                    onClick={() =>
                      handlePlanSelection(option.subscription_plan_id)
                    }
                  >
                    {option.button_text}
                  </button>
                  {/* <div className='col-5'>
              <div className='price'>${option.price}/booking</div>
            </div> */}
                  {/* <div className='col-12'>
            
            </div>
         */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlans;

