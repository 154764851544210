import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { Formik, ErrorMessage } from "formik";
import { callErrorApi } from '../errorHandle/callErrorApi';

function SignIn(props) {
  const navigate = useNavigate();

  // SEO setup
  useEffect(() => {
    const title = props.customer 
      ? "Signin | CHEF REPUBLIC" 
      : "Signin | CHEF | CHEF REPUBLIC";
    const desc = props.customer
      ? "Customer must signin or even Continue as guest in order to browse Private Chefs and various Cuisines/Diets"
      : "Signin your Personal Chef Account with Chef Republic to meal prep for our customers";

    document.title = title;
    document.querySelector("meta[name='description']").content = desc;
    document.querySelector("meta[property='og:title']").content = title;
    document.querySelector("meta[property='og:description']").content = desc;
    document.querySelector("meta[property='twitter:title']").content = title;
    document.querySelector("meta[property='twitter:description']").content = desc;
  }, [props.customer]);

  // const handleLoginError = (data, values, setFieldError) => {
  //   callErrorApi(`response_error: ${process.env.REACT_APP_BASE_URL}auth/login`, values.email, data);
  //   debugger
  //   if (data.data?.otp_verify === "0") {
  //     navigate(
  //       props.customer ? "/sign-up/verify-otp" : "/chef/sign-up/verify-otp",
  //       { state: { email: data.data.email || "" } }
  //     );
  //     return;
  //   }



  //   const errorMessage = data.messages?.error?.toLowerCase() || "";
  //   if (errorMessage.includes("email")) setFieldError('email', data.messages.error);
  //   if (errorMessage.includes("password")) setFieldError('password', data.messages.error);
  // };

  const handleLoginError = (data, values, setFieldError) => {
    callErrorApi(`response_error: ${process.env.REACT_APP_BASE_URL}auth/login`, values.email, data);
  
    if (data.data?.otp_verify === "0") {
      navigate(
        props.customer ? "/sign-up/verify-otp" : "/chef/sign-up/verify-otp",
        { state: { email: data.data.email || "" } }
      );
      return;
    }
  
    const errorMessage = data.messages?.error?.toLowerCase() || "";
    if (errorMessage.includes("email")) setFieldError('email', data.messages.error);
    if (errorMessage.includes("password")) setFieldError('password', data.messages.error);
    if (errorMessage.includes("invalid credentials")) setFieldError('password', "Incorrect password. Please try again.");
  };
  

  const handleCustomerLogin = async (data, values) => {
    const { chef_status, card_added, user_id, token, postal_code, user_image, plan_status } = data.data;

    // Set common storage items
    localStorage.setItem("token", token);
    localStorage.setItem("card_added", card_added);
    localStorage.setItem("user_image", user_image);
    localStorage.setItem("postalCode", localStorage.getItem("postalCode") || postal_code);

    // Fetch customer details
    await checksubcription(postal_code);
    const userResponse = await fetchUserDetails("user/loggedin_customer_detail", token);
    const customer = userResponse.data[0];

    // Update storage
    localStorage.setItem("owner", values.email);
    localStorage.setItem("logged_user_id", customer.customer_id);
    localStorage.setItem("plan_status", customer.plan_status || plan_status);

    // Navigation logic
    if (chef_status) return navigate("/subscription-summary");
    // if (!card_added) return navigate("/add-card-details", { state: { email: values.email } });

    const planStatus = localStorage.getItem("plan_status");
    const chefId = localStorage.getItem("chef_id");
    const redirectTo = localStorage.getItem("redirect_to");

    navigate(
      planStatus === 'true' ? (chefId ? "/subscription-detail" : "/chef-list") :
      planStatus === 'false' ? (redirectTo || "/subscription-plans") :
      redirectTo || "/chef-list"
    );
  };

  const handleChefLogin = async (data, values) => {
    const { token, postal_code, user_image, bank_added, profile_updated } = data.data;

    if (!token) {
      handleChefSetup(bank_added, profile_updated, values);
      return;
    }

    const chefResponse = await fetchUserDetails("user/loggedin_chef_detail", token);
    const chef = chefResponse.data[0];

    localStorage.setItem("owner", values.email);
    localStorage.setItem("token", token);
    localStorage.setItem("user_image", user_image);
    localStorage.setItem("postalCode", postal_code);
    localStorage.setItem("logged_user_id", chef.chef_id);
    localStorage.setItem("chef_id", chef.chef_id);

    navigate("/chef/order-list");
  };

  const fetchUserDetails = async (endpoint, token) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.json();
  };

  const handleChefSetup = (bankAdded, profileUpdated, values) => {
    if (!bankAdded) {
      navigate("/chef/add-payment-details", { state: { email: values.email } });
    } else if (!profileUpdated) {
      navigate("/chef/complete-profile", { state: { email: values.email } });
    } else {
      navigate("/under-review");
    }
  };

  const checksubcription = async (postalCode) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("logged_user_id"),
          postal_code: localStorage.getItem("postalCode") || postalCode
        })
      });

      const data = await response.json();
      if (data.status && !data.chef_status && localStorage.getItem("plan_id")) {
        await setUserSubscription(localStorage.getItem("plan_id"));
      }
    } catch (error) {
      console.error('Subscription check failed:', error);
    }
  };

  const setUserSubscription = async (planId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}subscription/add_subscription_plan`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          plan_id: planId,
          user_id: localStorage.getItem("logged_user_id")
        })
      });
      
      const data = await response.json();
      if (data.status) localStorage.setItem("plan_status", "true");
    } catch (error) {
      console.error('Subscription update failed:', error);
    }
  };

  return (
    <div className="row m-0 p-0">
      {/* Left Column */}
      <div
        className="col-md-7"
        style={{
          background: `linear-gradient(to left, rgba(255, 255, 255, 1) 0%,  rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 0.2) 18%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 100%), url(${props.image}) no-repeat`,
          height: "100vh",
          backgroundSize: "auto 100%",
          display: isMobile ? "none" : "block",
        }}
      />

      {/* Right Column */}
      <div className="col-md-5 d-flex align-items-center pt-5 justify-content-center flex-column">
        <div className="d-flex justify-content-between" style={{ width: isMobile ? "90%" : "70%" }}>
          <h2>
            <span style={{ color: "#FF5E41", fontWeight: 700 }}>Sign In</span>
          </h2>
          <h2>
            <Link
              to={props.customer ? "/sign-up" : "/chef/sign-up"}
              style={{
                textDecoration: "none",
                color: "rgba(0, 0, 0, .5)",
                fontWeight: 700
              }}
            >
              Sign Up
            </Link>
          </h2>
        </div>

        <div className="d-flex justify-content-start mt-2 mb-3" style={{ width: isMobile ? "90%" : "70%" }}>
          <h4 style={{ fontSize: "16px", width: "320px" }}>
            {props.customer ? "to hire a private chef on demand" : "to get more clients"}
          </h4>
        </div>

        {/* Formik Form */}
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email is Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            if (!values.password) {
              errors.password = 'Password is Required';
            } else if (values.password.length < 8) {
              errors.password = 'Password must be of 8 Character';
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const response = await fetch(`${process.env.REACT_APP_BASE_URL}auth/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(values),
              });

              const data = await response.json().catch(() => ({}));
              
              if (data.status === 400) {
                handleLoginError(data, values, setFieldError);
              }

              if (!data?.status ) {
                handleLoginError(data, values, setFieldError);
                return;
              }

              // Common storage items
              localStorage.setItem("role", data.data.role);
              localStorage.setItem("chef_status", data.data.chef_status);
              localStorage.setItem("logged_user_id", data.data.user_id);

              // Role handling
              data.data.role === "1" 
                ? await handleCustomerLogin(data, values)
                : await handleChefLogin(data, values);

            } catch (error) {
              callErrorApi(`catch_error: ${process.env.REACT_APP_BASE_URL}auth/login`, values.email, error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form 
              style={{ width: isMobile ? "90%" : "70%", paddingBottom: "60px" }}
              onSubmit={handleSubmit}
            >
              {/* Form Fields */}
              <div className="mb-3">
                <input
                  placeholder="Email ID"
                  type="email"
                  className={`form-control shadow-none ${errors.email ? 'invalid' : ''}`}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <ErrorMessage name="email">{msg => <h6 className="error-msg">{msg}</h6>}</ErrorMessage>
              </div>

              <div className="mb-3">
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control shadow-none ${errors.password ? 'invalid' : ''}`}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {/* <ErrorMessage name="password">{msg => <h6 className="error-msg">{msg}</h6>}</ErrorMessage> */}
                <ErrorMessage name="password">
    {msg => <h6 className="error-msg">{msg}</h6>}
  </ErrorMessage>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="m-2 btn-orange w-100"
                  disabled={isSubmitting}
                >
                  {isSubmitting && <span className="spinner-border spinner-border-sm me-2"></span>}
                  Sign In
                </button>
              </div>

              <h2 className="mb-4 text-center">
                <Link
                  to={props.customer ? "/forgot-password" : "/chef/forgot-password"}
                  style={{ textDecoration: "none", color: "#FF5E41" }}
                >
                  <span style={{ fontSize: "16px" }}>Forgot Password?</span>
                </Link>
              </h2>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SignIn;