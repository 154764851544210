import React from "react";
import { isMobile } from "react-device-detect";
import coverStatges from "../images/statsbg1.png";


function Websitecards() {
  return (
    <div id="websiteCards"
    
    style={{

      background: `url(${coverStatges})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      
    }}
    >
<div className="row justify-content-center "  >
<div className="col-md-2 col-6 p-3  text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: '#D45533', color: '#fff' }}>
    <h2>1.5 +</h2>
    <p style={{ fontSize: isMobile ? "13px" : "18px" }} >Years since our launch</p>
  </div>
</div>
<div className="col-md-2 col-6 p-3  text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: '#D45533', color: '#fff' }}>
    <h2>40 +</h2>
    <p style={{ fontSize: isMobile ? "13px" : "18px" }}> Chefs have joined us</p>
  </div>
</div>
<div className="col-md-2 p-3 col-6  text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: '#FFFFFF', color: '#D45533' }}>
    <h2>2000 +</h2>
    <p style={{ fontSize: isMobile ? "13px" : "18px" }}>Guests enjoyed our experience</p>
  </div>
</div>
<div className="col-md-2 p-3  col-6 text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: 'rgba(212, 85, 51, 1)', color: '#fff' }}>
    <h2>20 +</h2>
    <p style={{ fontSize: isMobile ? "13px" : "18px" }}>Cities Available</p>
  </div>
</div>
</div>
    </div>

  );
}

export default Websitecards;











