import Alert from '@mui/material/Alert';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaPencilAlt } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { callErrorApi } from '../errorHandle/callErrorApi';
import Vector from "../images/Vector.jpg";
import PurifyHtml from './PurifyHtml';
import Toolbar from "./Toolbar";
import { Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { RiCloseLine, RiPencilLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import getLatLong from '../helpers/getLatLong';


function CompleteChefProfile(props) {

  let role = props.customer ? "1" : "2";
  const currentLocation = useLocation();
  const { email, line1, line2, city, state, postal_code, working_radius } = currentLocation.state;

  const [chefAddress, setChefAddress] = useState({
    line1: line1, line2: line2, city: city, state: state, country: 'US', postal_code: postal_code,
    latitude: '', longitude: '', working_radius: working_radius
  });

  const [addressLoading, setAddressLoading] = useState(false);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);


  // file upload state
  const [profilePic, setProfilePic] = useState(null);
  const [displayMenu, setDisplayMenu] = useState(null);
  const [menuImageVisible, setFoodImageVisible] = useState([]);
  const [menu, setMenu] = useState(null);
  const [food, setFood] = useState(null);
  const [fileName, setfileName] = useState("");
  let navigate = useNavigate();
  const [diets, setDiets] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [credentials, setCredentials] = useState({
    address: localStorage.getItem("address"),
    aboutChef: "",
    city: localStorage.getItem("city"),
  });


  // ======================================================

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);


  useEffect(() => {
    var title = `Complete Profile | CHEF REPUBLIC`;
    var desc = "Create Chef Profile: Create and Update your Chef Profile by selecting the diets and cuisine you are interested in cooking and updating your menu and pictures of your meal prep.";
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title);
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc);
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title);
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc);
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title);
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc);

    if (role && (role === null || role !== "2")) {
      navigate("/chef/sign-in");
      window.location.reload();
    }
    console.log(currentLocation.state);
    if (currentLocation.state === null || currentLocation.state === undefined) {
      navigate("/sign-in");
      window.location.reload();
    }
    updateDiets();
    getLatLongFromInitialAddressData(line1, line2, city, state, postal_code, working_radius);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    convertContentToHTML();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(currentContentAsHTML);
  };

  // ======================================================


  function removeItemOnce(arr, value) {
    setFoodImageVisible(menuImageVisible.filter(item => item !== value));
  }

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const updateDiets = async () => {
    try {
      let data = await fetch(
        `${process.env.REACT_APP_BASE_URL}general/categories`
      );
      let parsedData = await data.json();
      setDiets(parsedData.data);
    } catch (error) {
      callErrorApi(`catch_error: ${process.env.REACT_APP_BASE_URL}general/categories`, email, error);
    }
  };


  const updateList = (file_imp) => {
    let objectURL;
    for (var i = 0; i < file_imp.length; ++i) {
      objectURL = URL.createObjectURL(file_imp[i]);
      menuImageVisible.push(objectURL);
      setFoodImageVisible(menuImageVisible);
    }
  };

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleClick1 = () => {
    inputRef1.current.click();
  };

  const handleClick2 = () => {
    inputRef2.current.click();
  };

  function showFilemenu() {
    $("#showFilemenu").hide();
    $("#Filemenuimpit").removeClass("d-none");
    $("#Filemenuimpit").addClass("d-flex");
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setProfilePic(event.target.files[0]);
    event.target.value = null;
    toBase64(fileObj).then((data) => {
      $("#imgUpload").attr("src", data);
    });
  };

  const validateFoodFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (event.target.files.length > 5) {
      event.target.value = null;
      alert("Maximum 5 food picture you can upload");
      while (menuImageVisible.length > 0) {
        menuImageVisible.pop();
      }
    } else {
      let file_arr = [];
      updateList(event.target.files);
      for (var n = 0; n < event.target.files.length; n++) {
        file_arr.push(event.target.files[n]);
      }
      setFood(file_arr);
      event.target.value = null;
    }
  };

  const validateMenuFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setMenu(event.target.files[0]);
    setfileName(event.target.files[0].name);
    var files_menu = URL.createObjectURL(event.target.files[0]);
    setDisplayMenu(files_menu);
    $("#Filemenuimpit").addClass("d-none");
    $("#Filemenuimpit").removeClass("d-flex");
    event.target.value = null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking empty Menu 
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const menuText = rawContentState.blocks[0].text.trim();

    setIsSubmitting(true);
    try {
      var yourArray = [];
      $("input:checkbox[name=diet-menu]:checked").each(function () {
        yourArray.push($(this).val());
      });
      var monday = [];
      $("input:checkbox[name=monday]:checked").each(function () {
        monday.push($(this).val());
      });
      var tuesday = [];
      $("input:checkbox[name=tuesday]:checked").each(function () {
        tuesday.push($(this).val());
      });
      var wednesday = [];
      $("input:checkbox[name=wednesday]:checked").each(function () {
        wednesday.push($(this).val());
      });
      var thursday = [];
      $("input:checkbox[name=thursday]:checked").each(function () {
        thursday.push($(this).val());
      });
      var friday = [];
      $("input:checkbox[name=friday]:checked").each(function () {
        friday.push($(this).val());
      });
      var saturday = [];
      $("input:checkbox[name=saturday]:checked").each(function () {
        saturday.push($(this).val());
      });
      var sunday = [];
      $("input:checkbox[name=sunday]:checked").each(function () {
        sunday.push($(this).val());
      });

      if (!profilePic) {
        alert("Please upload your profile Pic");
        setIsSubmitting(false);
        return false;
      }

      if (!chefAddress.latitude || !chefAddress.longitude) {
        alert("Please ensure your address is correct. Don't forget to click 'Update Address' button after filling it in the 'Update Your Address' popup.");
        setIsSubmitting(false);
        return false;
      }

      if (!chefAddress.line1 || !chefAddress.line2 || !chefAddress.city || !chefAddress.state || !chefAddress.country || !chefAddress.postal_code) {
        let missingFields = [];
        if (!chefAddress.line1) missingFields.push("Address Line 1");
        if (!chefAddress.line2) missingFields.push("Address Line 2");
        if (!chefAddress.city) missingFields.push("City");
        if (!chefAddress.state) missingFields.push("State");
        if (!chefAddress.country) missingFields.push("Country");
        if (!chefAddress.postal_code) missingFields.push("Zip Code");

        alert(`Please add your address. The following field(s) are empty: ${missingFields.join(", ")}`);

        setIsSubmitting(false);
        return false;
      }

      if (!chefAddress.working_radius) {
        alert("Please add your working radius");
        setIsSubmitting(false);
        return false;
      }

      if (!credentials.aboutChef) {
        alert("Please add about yourself");
        setIsSubmitting(false);
        return false;
      }
      if (yourArray.length === 0) {
        alert("Please add Food Category");
        setIsSubmitting(false);
        return false;
      }
      if (!food) {
        alert("Please upload some Food Images");
        setIsSubmitting(false);
        return false;
      }
      if (menuText === '') {
        alert("Please add your menu in the 'Design Your Menu' section.");
        setIsSubmitting(false);
        return false;
      }
      if (!monday || !tuesday || !wednesday || !thursday || !friday || !saturday || !sunday) {
        alert("Please add your Availability Time");
        setIsSubmitting(false);
        return false;
      }

      var myHeaders = new Headers();
      var formdata = new FormData();

      formdata.append("profile_photo", profilePic);
      formdata.append("address", credentials.address);
      formdata.append("food_categories", yourArray.join(","));
      formdata.append("about", credentials.aboutChef);
      formdata.append("menu", menu);

      formdata.append("line1", chefAddress.line1);
      formdata.append("line2", chefAddress.line2);
      formdata.append("city", chefAddress.city);
      formdata.append("state", chefAddress.state);
      formdata.append("country", chefAddress.country);
      formdata.append("postal_code", chefAddress.postal_code);

      formdata.append("user_address_latitude", chefAddress.latitude);
      formdata.append("user_address_longitude", chefAddress.longitude);
      formdata.append("working_radius", chefAddress.working_radius);

      var PurifiedMenuContent = PurifyHtml(convertedContent);
      formdata.append("rich_text_menu", PurifiedMenuContent);

      if (food) {
        for (var n = 0; n < food.length; n++) {
          formdata.append("food_pictures[]", food[n]);
        }
      } else {
        formdata.append("food_pictures", []);
      }

      formdata.append("email", email);
      formdata.append(
        "availability_time",
        JSON.stringify({
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
        })
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}user/update_chef_details_first_time`,
        requestOptions
      );
      const res_json = await response.text();
      const data = JSON.parse(res_json);
      if (data.status === true) {
        localStorage.removeItem("address");
        setIsSubmitting(false);
        toast.success('Profile updated');
        navigate("/under-review");
      } else {
        callErrorApi(`response_error: ${process.env.REACT_APP_BASE_URL}user/update_chef_details_first_time `, email, data);
        setIsSubmitting(false);
        toast.error('Error occurred, please try later');
      }

    } catch (error) {
      callErrorApi(`catch_error: ${process.env.REACT_APP_BASE_URL}user/update_chef_details_first_time`, email, error);
      setIsSubmitting(false);
      toast.error('Error occurred, please try later');
    }
  };

  const getLatLongFromInitialAddressData = async (line1, line2, city, state, postal_code, working_radius) => {
    var initialData = {
      line1: line1, line2: line2, city: city, state: state,
      country: 'US', postal_code: postal_code, working_radius: working_radius
    };
    console.log(initialData);
    if (initialData.postal_code.toString().length !== 5) {
      toast.error('Please enter a valid Zip code');
      return;
    }
    setAddressLoading(true);
    // console.log(initialData);
    // Fetch latitude and longitude for given address
    try {
      const coordinates = await getLatLong(initialData);
      if (coordinates) {
        var user_address_latitude = coordinates.latitude;
        var user_address_longitude = coordinates.longitude;
        console.log(initialData);
        setChefAddress({ ...initialData, latitude: user_address_latitude, longitude: user_address_longitude });
        // console.log(user_address_latitude);
        // console.log(user_address_longitude);
      } else {
        setChefAddress({ ...initialData, latitude: null, longitude: null });
        console.log('Failed to retrieve coordinates.');
        toast.error('Please enter a valid proper address or double-check your address');
      }
    } catch (error) {
      setChefAddress({ ...initialData, latitude: null, longitude: null });
      console.error('Error:', error);
      toast.error('Failed to fetch precise location, try again later.');
    } finally {
      setAddressLoading(false);
    }
  };


  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    console.log(chefAddress);
    if (chefAddress.postal_code.toString().length !== 5) {
      toast.error('Please enter a valid Zip code');
      return;
    }
    setAddressLoading(true);
    // console.log(chefAddress);
    // Fetch latitude and longitude for given address
    try {
      const coordinates = await getLatLong(chefAddress);
      if (coordinates) {
        var user_address_latitude = coordinates.latitude;
        var user_address_longitude = coordinates.longitude;
        setChefAddress({ ...chefAddress, latitude: user_address_latitude, longitude: user_address_longitude });
        // console.log(user_address_latitude);
        // console.log(user_address_longitude);
        toast.success('Address Updated!');
      } else {
        setChefAddress({ ...chefAddress, latitude: null, longitude: null });
        console.log('Failed to retrieve coordinates.');
        toast.error('Please enter a valid proper address or double-check your address');
      }
    } catch (error) {
      setChefAddress({ ...chefAddress, latitude: null, longitude: null });
      console.error('Error:', error);
      toast.error('Failed to fetch precise location, try again later.');
    } finally {
      setAddressLoading(false);
    }
  };

  const addressParts = [
    chefAddress.line1,
    chefAddress.line2,
    chefAddress.city,
    chefAddress.state,
    chefAddress.country,
    chefAddress.postal_code
  ];

  const filteredAddressParts = addressParts.filter(part => part); // Remove empty parts

  const address = filteredAddressParts.join(", "); // Join parts with comma

  return (
    <>
      {/* ------ Modal for Address Selection ------- */}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        onClose={() => setOpenAddressDialog(false)}
        open={openAddressDialog}
      >
        <DialogTitle>Update Your Address</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenAddressDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseLine />
        </IconButton>
        <DialogContent dividers>
          <form onSubmit={handleAddressSubmit}>
            <div className="mb-3">
              <input placeholder="Address Line 1" type="text" required
                className='form-control'
                name="line1"
                onChange={(e) => setChefAddress({ ...chefAddress, line1: e.target.value })}
                value={chefAddress.line1}
              />
            </div>
            <div className="mb-3">
              <input placeholder="Address Line 2" type="text" required
                className='form-control'
                name="line2"
                onChange={(e) => setChefAddress({ ...chefAddress, line2: e.target.value })}
                value={chefAddress.line2}
              />
            </div>

            <div className='row mb-3 g-3'>
              <div className="col-12 col-md-4  ">
                <input placeholder="City" type="text" required
                  className='form-control'
                  name="city"
                  onChange={(e) => setChefAddress({ ...chefAddress, city: e.target.value })}
                  value={chefAddress.city}
                />
              </div>


              <div className="col-12 col-md-4  ">
                <input placeholder="State" type="text" required
                  className='form-control'
                  name="state"
                  onChange={(e) => setChefAddress({ ...chefAddress, state: e.target.value })}
                  value={chefAddress.state}
                />
              </div>

              <div className="col-12 col-md-4  ">
                <input placeholder="Country" type="text" required
                  className='form-control'
                  name="country"
                
                  value={chefAddress.country}
                  disabled={true}

                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className="col-12 col-md-6  ">
                <input placeholder="Zip Code" type="number" required
                  className='form-control mb-1'
                  name="postal_code"
                  onChange={(e) => setChefAddress({ ...chefAddress, postal_code: e.target.value })}
                  value={chefAddress.postal_code}
                />
                <p className='mx-2' style={{ fontSize: '0.7rem' }}>Enter a valid 5-digit Zip code</p>
              </div>

              <div className="col-12 col-md-6 ">
                <input placeholder="Working Radius (miles)" type="number" required
                  className='form-control mb-1'
                  name="working_radius"
                  onChange={(e) => setChefAddress({ ...chefAddress, working_radius: e.target.value })}
                  value={chefAddress.working_radius}
                />
                <p className='mx-2' style={{ fontSize: '0.7rem' }}>Enter the maximum distance (in approx. miles) you can travel for cooking.</p>
              </div>
            </div>


            <div className="mb-3">
              <button className="btn-orange" type='submit' disabled={addressLoading}>{addressLoading && <span className="spinner-border spinner-border-sm me-2" ></span>} Update Address</button>
            </div>
          </form>

        </DialogContent>
      </Dialog>

      <Nav customer={props.customer} />
      <div className="container-fluid my-5">
        <div className='row'>
          <div className={`mx-auto col-12 col-md-7 rounded-5 shadow border border-opacity-50 ${isMobile ? 'card' : ''}`}    >
            <div className='my-4'>
              <h4 className="fw-bold text-center mb-3">Hello {localStorage.getItem("user_name")}!</h4>
              <h6 className="text-center  ">
                Craft an Eye-Catching Profile to Delight Your Customers!
              </h6>
            </div>

            <form
              id="profile-chef-complete"
              className="align-items-center d-flex flex-column"
              onSubmit={handleSubmit}
            >
              {/* <div className="profile-img">
                <span
                  className="d-flex align-items-center justify-content-center mb-4"
                  style={{}}
                >
                  <img
                    src={Vector}
                    alt=""
                    id="imgUpload"
                    style={{
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                      width: "120px",
                      height: "120px",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                  />
                </span>
                <span
                  className="add-media d-flex align-items-center justify-content-center"
                  onClick={handleClick}
                  style={{
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",    
                  }}
                >
                  <FaPencilAlt />
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    name="profilePic"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </span>
              </div> */}






              <div className="profile-img">
                                        <span className=" align-items-center justify-content-center mb-4" >
                                            <img
                                                 src={Vector}
                                                alt=""
                                                id="imgUpload"
                                                style={{
                                                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                                    width: "120px",
                                                    height: "120px",
                                                    padding: "10px",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        </span>
                                        <span className="align-items-center justify-content-center"
                                            onClick={handleClick}
                                            style={{
                                                position: "absolute",
                                                borderRadius: "50%",
                                                backgroundColor: "white",
                                                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                                cursor: "pointer",                                              
                                            }}
                                        >
                                            <FaPencilAlt />
                                            <input
                                                style={{ display: "none" }}
                                                ref={inputRef}
                                                type="file"
                                                name="profilePic"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </span>
                                    </div>

              <div   className={`shadow px-3 pt-3 shadow-sm rounded-3 col-12 col-md-5 mb-3 ${isMobile ? 'card' : ''}`}>
                <div className="d-flex justify-content-between ">
                  <p htmlFor="Input" className="form-label fw-semibold m-0">My Address</p>
                  <IconButton type="button" size='small' onClick={() => setOpenAddressDialog(true)}> <RiPencilLine /> </IconButton>
                </div>
                <Divider className='my-2' />
                <h6 className="text-wrap" onClick={() => setOpenAddressDialog(true)}>
                  {filteredAddressParts.length === 0 ? "No Address" : address + "."}
                </h6>
                <Divider />
                <p className="text-wrap small text-success mt-1 fw-semibold" onClick={() => setOpenAddressDialog(true)}>{`Working Radius (approx. miles): ${(chefAddress.working_radius === '') ? '--' : (chefAddress.working_radius ?? '--')}`}</p>
              </div>

              {/* <div className="my-2">
                <input
                  type="text"
                  className="form-control shadow-none"
                  value={credentials.address}
                  onChange={onChange}
                  name="address"
                  style={{ maxWidth: "350px", minWidth: "300px" }}
                  placeholder="Enter your Home Address"
                />
              </div>
              <div className="my-2">
                <input
                  type="text"
                  className="form-control shadow-none"
                  value={credentials.city}
                  onChange={onChange}
                  name="city"
                  style={{ maxWidth: "350px", minWidth: "300px" }}
                  placeholder="Enter your City"
                />
              </div> */}

              <div className="my-2">
                <input
                  type="text"
                  className="form-control shadow-none"
                  value={credentials.aboutChef}
                  onChange={onChange}
                  name="aboutChef"
                  style={{ maxWidth: "350px", minWidth: "300px" }}
                  placeholder="About Me"
                />
              </div>
              <hr className='my-5' style={{ border: "1px solid grey", width: "80%" }} />
              <h6
                style={{
                  width: "280px",
                  textAlign: "center",
                  fontWeight: "700",
                  lineHeight: "26px",
                  fontSize: "0.9rem",
                }}
              >
                Choose Your Cooking Preferences
              </h6>
              <p className='small w-75 text-center'>
                Pick the type of cuisine and dietary preferences you're happy to cook. Let us know what you enjoy making!
              </p>
              <div className="row" style={{ width: "95%" }}>
                {diets.map((element, index) => {
                  return (
                    <div className="col-6 col-md-4 my-2" key={index}>
                      <input type="checkbox" id={"card-" + element.id.toString()} value={element.id} name="diet-menu" className="Send_data input-hidden"
                      />
                      <label
                        className="time-availablibilty-card"
                        htmlFor={"card-" + element.id.toString()}
                        style={{
                          backgroundImage: `url(${element.category_image})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      >
                        {element.category_name}
                      </label>
                    </div>
                  );
                })}
              </div>
              <hr className='my-5' style={{ border: "1px solid grey", width: "80%" }} />
              <h6
                style={{
                  width: "280px",
                  textAlign: "center",
                  fontWeight: "700",
                  lineHeight: "26px",
                  fontSize: "0.9rem",
                }}
              >
                Showcase Your Delicious Creations
              </h6>
              <p className='small w-75 text-center'>
                Upload up to 5 pictures of the food you've cooked. Show off your best dishes for everyone to see!
              </p>
              <div className="row" style={{ width: "90%" }}>
                {menuImageVisible.length > 0 ? menuImageVisible.map((element, index) => {
                  return (<div key={index} className={`col-4 m-2 p-2 img-${index}`} style={{ position: "relative" }} onClick={() => removeItemOnce(menuImageVisible, element)}>
                    <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", right: "1%", cursor: "pointer", color: "white", backgroundColor: "rgba(52, 52, 52, 0.9)", borderRadius: "50%" }}><MdOutlineCancel style={{ fontSize: "1.2rem" }} /></div>
                    <img src={element} style={{ width: "100%" }} alt="" />
                  </div>);
                }) : null}
              </div>
              <div
                className={menuImageVisible.length < 5 ? "add-media d-flex align-items-center justify-content-center flex-column" : "d-none"}
                onClick={handleClick1}
                style={{
                  cursor: "pointer",
                  width: "319px",
                  height: "147px",
                  background: "#FFDFD9",
                  borderRadius: "20px",
                }}
              >
                <FiUpload style={{ color: "#FF5E41", fontSize: "1.25rem" }} />
                <h6 style={{ fontSize: "0.9rem", paddingTop: "10px" }}>
                  {" "}
                  Upload Photos
                </h6>
                <input
                  style={{ display: "none" }}
                  ref={inputRef1}
                  type="file"
                  accept="image/*"
                  multiple="multiple"
                  onChange={validateFoodFileChange}
                />
              </div>
              <hr className='my-4' style={{ border: "1px solid grey", width: "80%" }} />


              {/* ===== Rich Text Menu Block Start ===== */}

              <h6 style={{ width: "280px", textAlign: "center", fontWeight: "700", lineHeight: "26px", fontSize: "0.9rem" }} >
                Design Your Menu
              </h6>
              <p className='small w-75 text-center'>
                As a chef, you can design your very own menu. Pick your best dishes, showcase your specialties, and get ready to impress your customers with your culinary creations.
              </p>
              <div className='px-3'>
                <Alert severity="info" className='mb-2'>Please ensure that all headings are formatted as <strong>H4 and Bold</strong>, while the remaining text should be in normal font with a <strong>font size of 14</strong>.</Alert>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={Toolbar}
                />
              </div>

              {/* ===== Rich Text Menu Block End ===== */}

              <hr className='my-5' style={{ border: "1px solid grey", width: "80%" }} />
              <h6
                style={{
                  width: "280px",
                  textAlign: "center",
                  fontWeight: "700",
                  lineHeight: "26px",
                  fontSize: "0.9rem",
                }}
              >
                Share Your Availability
              </h6>
              <p className='small w-75 text-center'>
                Let users know when you're available by updating your schedule. They can then easily choose a time that works for them.
              </p>
              <div className="row" style={{ width: "95%" }}>
                <div className="col-sm-6 col-md-6 my-2">
                  <h6 style={{ fontSize: "16px", fontWeight: "400" }}>Monday</h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-8-monday" name="monday" className="Send_data input-hidden" value="8am-11am"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-8-monday"
                      >
                        8am-11am
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-12-monday" name="monday" className="Send_data input-hidden" value="12pm-3pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-12-monday"
                      >
                        12pm-3pm
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-4-monday" name="monday" className="Send_data input-hidden" value="4pm-7pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-4-monday"
                      >
                        4pm-7pm
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 my-2">
                  <h6 style={{ fontSize: "16px", fontWeight: "400" }}>Tuesday</h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-8-tuesday" name="tuesday" className="Send_data input-hidden" value="8am-11am"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-8-tuesday"
                      >
                        8am-11am
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-12-tuesday" name="tuesday" className="Send_data input-hidden" value="12pm-3pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-12-tuesday"
                      >
                        12pm-3pm
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-4-tuesday" name="tuesday" className="Send_data input-hidden" value="4pm-7pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-4-tuesday"
                      >
                        4pm-7pm
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 my-2">
                  <h6 style={{ fontSize: "16px", fontWeight: "400" }}>
                    Wednesday
                  </h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-8-wednesday" name="wednesday" className="Send_data input-hidden" value="8am-11am"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-8-wednesday"
                      >
                        8am-11am
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-12-wednesday" name="wednesday" className="Send_data input-hidden" value="12pm-3pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-12-wednesday"
                      >
                        12pm-3pm
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-4-wednesday" name="wednesday" className="Send_data input-hidden" value="4pm-7pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-4-wednesday"
                      >
                        4pm-7pm
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 my-2">
                  <h6 style={{ fontSize: "16px", fontWeight: "400" }}>
                    Thursday
                  </h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-8-thursday" name="thursday" className="Send_data input-hidden" value="8am-11am"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-8-thursday"
                      >
                        8am-11am
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-12-thursday" name="thursday" className="Send_data input-hidden" value="12pm-3pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-12-thursday"
                      >
                        12pm-3pm
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-4-thursday" name="thursday" className="Send_data input-hidden" value="4pm-7pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-4-thursday"
                      >
                        4pm-7pm
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 my-2">
                  <h6 style={{ fontSize: "16px", fontWeight: "400" }}>Friday</h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-8-friday" name="friday" className="Send_data input-hidden" value="8am-11am"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-8-friday"
                      >
                        8am-11am
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-12-friday" name="friday" className="Send_data input-hidden" value="12pm-3pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-12-friday"
                      >
                        12pm-3pm
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-4-friday" name="friday" className="Send_data input-hidden" value="4pm-7pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-4-friday"
                      >
                        4pm-7pm
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 my-2">
                  <h6 style={{ fontSize: "16px", fontWeight: "400" }}>
                    Saturday
                  </h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-8-saturday" name="saturday" className="Send_data input-hidden" value="8am-11am"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-8-saturday"
                      >
                        8am-11am
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-12-saturday" name="saturday" className="Send_data input-hidden" value="12pm-3pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-12-saturday"
                      >
                        12pm-3pm
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-4-saturday" name="saturday" className="Send_data input-hidden" value="4pm-7pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-4-saturday"
                      >
                        4pm-7pm
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 my-2">
                  <h6 style={{ fontSize: "16px", fontWeight: "400" }}>Sunday</h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-8-sunday" name="sunday" className="Send_data input-hidden" value="8am-11am"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-8-sunday"
                      >
                        8am-11am
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-12-sunday" name="sunday" className="Send_data input-hidden" value="12pm-3pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-12-sunday"
                      >
                        12pm-3pm
                      </label>
                    </div>
                    <div className="col-md-4 p-0 mx-1">
                      <input type="checkbox" id="radiobtn-4-sunday" name="sunday" className="Send_data input-hidden" value="4pm-7pm"
                      />
                      <label
                        className="time-availablibilty"
                        htmlFor="radiobtn-4-sunday"
                      >
                        4pm-7pm
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-orange my-4"
                style={{ width: "300px" }}
              >
                {isSubmitting && (<span className="spinner-border spinner-border-sm me-2"></span>)}
                Create Profile
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer top={1} />
    </>
  );
}

export default CompleteChefProfile;
