import React, {useEffect, useState} from 'react';
import { isMobile } from "react-device-detect";
import coverBgCustomer from "../images/coverBgCustomer.png";
import { Link, useNavigate } from "react-router-dom";
import ZipCodeDialog from "./ZipCodeDialog";


function CustomerCover() {

  let navigate = useNavigate();

  const [currentPlan,SetPlanData]=useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handlePricingClick = () => {
    const LoginUserID = localStorage.getItem("logged_user_id") ?? null;
    if (!LoginUserID) {
      setIsDialogOpen(true); // Open the dialog if LoginUserID is null
    } else {
      navigate("/subscription-plans"); // Route to /subscription-plans if logged in
    }
  };

  const handleDialogClose = (zipCode) => {
    setIsDialogOpen(false);
    if (zipCode) {
      console.log("Submitted Zip Code:", zipCode);
    }
  };


  useEffect(() => {    
    checksubcription();    
     }, []);
  
  const checksubcription = async () => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`,
        {
          method: 'POST',
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "user_id": `${localStorage.getItem("logged_user_id")}`,
              "postal_code": `${localStorage.getItem("postalCode")}`
          })
        }
      );
  
     
      const res_json = await response.text();
      const CurrentPlan = JSON.parse(res_json);
  
      if (CurrentPlan.status === true) {
        console.log(CurrentPlan);
        SetPlanData(CurrentPlan);
      } else {
        console.error('Error: Response status is false');
      }
    } catch (error) {
      console.error('Error while fetching data, please try later:', error);
    }
  };

  return (
    <>
      <div id="cover">
        <div
          id="customercoverBack"
          style={{
            background: `url(${coverBgCustomer}) bottom center`,

          }}
          className="d-flex flex-column align-items-center"
        ></div>
        <div
          id="customercoverShadow"
        >
          <div className="d-flex flex-column align-items-center" id="customercover">
            <h1 className="text-center mt-4 fw-bold ">
              On Demand <br />Home Chef Service
            </h1>
            <h5 className="text-center mt-3 px-5 px-md-0">
              Book Your Home Chef to Cook/Meal Prep <br className="d-none d-md-block" /> Upto 4 Meals or Less, Each for Serving Size of Upto 5 People or Less
            </h5>
            {/* <h4 className="text-center fs-3 fw-bold mb-3">
            , for Fixed Price of  $109
            </h4> */}
            <div className="text-center mt-3">
              <Link to="/sign-up" className="btn-orange mx-2">
                Sign Up
              </Link>
              <Link to={
                localStorage.getItem("token") && localStorage.getItem("chef_id") && localStorage.getItem("role") === "2" ? "/chef/order-list"
                  : localStorage.getItem("token") && localStorage.getItem("role") !== "2" && localStorage.getItem("logged_user_id")
                    ?   (currentPlan.plan_id?"/subscription-summary": "/chef-list") : "/sign-in"} className="btn-white mx-2">
                Sign In
              </Link>
            </div>
            <div className="text-center mt-4">
            { sessionStorage.getItem('role')!==2 && isMobile ? (<button className="btn-white mx-2" onClick={handlePricingClick}>
                Pricing
              </button>) : null}
            {/* {sessionStorage.getItem('role')!==2 && isMobile ? (
              
                <Link to="/subscription-plans" className="prcing-button-color"> Pricing</Link>) : null} */}
            </div>
          </div>
        </div>
      </div>

      <ZipCodeDialog 
        isOpen={isDialogOpen} 
        onClose={handleDialogClose} 
        imagesrc="path_to_image" 
        title="Enter Zip Code" 
        getDialog={1} 
        showSecondButton={true} 
        secondButtonText="Cancel" 
        changeScreenSecondButton={() => console.log("Second Button Clicked")} 
      />    

    </>
  );
}

export default CustomerCover;
