import React, { useRef, useEffect, useState } from "react";
import LoggedNav from "../components/LoggedNav";
import { Link, useNavigate } from "react-router-dom";
import { RiArrowLeftSLine, RiPencilLine, RiArrowRightLine } from 'react-icons/ri';
import profile from "../images/Vector.jpg";
import Spinner from "./BePatient";
import $ from "jquery";
import { isMobile } from "react-device-detect";
import MobileUpdateModal from '../components/MobileUpdateModal';
import AddressUpdateModal from '../components/AddressUpdateModal';
import EmailUpdateModal from '../components/EmailUpdateModal';
import { FaPencilAlt } from "react-icons/fa"
import DynamicDialog  from "../components/DynamicDialog";

import '../CSS File/popupdesign.css';
function Profile(props) {
  const [PLanDetails,setactivePlan]=useState('');
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const updateProfile = async (image) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var formdata = new FormData();
    formdata.append("profile_photo", image);
    formdata.append("type", "profile_image");
    var response = await fetch(
      `${process.env.REACT_APP_BASE_URL}user/update_user_details`,
      {
        headers: myHeaders,
        method: "POST",
        body: formdata,
        redirect: "follow",
      }
    );
    const res_json = await response.text();
    const data = JSON.parse(res_json);
    if (data.status) { customerDetails(); }
  };
  const customerDetails = async () => {
    setLoading(true);
    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}user/loggedin_customer_detail`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const res_json = await response.text();
    const data = JSON.parse(res_json);
    if (data.status === true) {
      debugger
      setCustomer(data.data[0]);
      localStorage.setItem("user_image", data.data[0].customer_profile_image);
    } else {
      setLoading(false);
    }
    setLoading(false);
  };
  let role = localStorage.getItem("role");
  let token = localStorage.getItem("token");



  const checksubcription = async () => {
   
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`,
        {
          method: 'POST',
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "user_id": `${localStorage.getItem("logged_user_id")}`,
              "postal_code": `${localStorage.getItem("postalCode")}`
        })
        }
      );
      const res_json = await response.text();
      const data = JSON.parse(res_json);
      if (data.status === true) {
       console.log(data);
       setactivePlan(data);
      } else {
        console.error('Error while fetching data, please try later');
      }
    
  };


  const [openDialog, setOpenDialog] = useState(false);
  const mySubcriptionDetialCheck =()=>{

    navigate("/subscription-summary");
    // if(PLanDetails.chef_status && PLanDetails.plan_status){
    //   navigate("/subscription-summary");
    // }else{
    //   setOpenDialog(!openDialog)
    // }


  };





  useEffect(() => {


    var title = `Profile | CHEF REPUBLIC`;
    var desc = "";
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title);
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc);
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title);
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc);
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title);
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc);
    checksubcription();
    if (role === null || role !== "1") {
      navigate("/sign-in");
      window.location.reload();
    }
    if (token === null || token === undefined) {
      navigate("/sign-in");
      window.location.reload();
    }
    customerDetails();
    // eslint-disable-next-line
  }, []);
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    updateProfile(event.target.files[0]);
    event.target.value = null;
    toBase64(fileObj).then((data) => {
      $("#profile_pic").attr("src", data);
    });
  };



  
  const changeScreen = () => {
    if(!PLanDetails.chef_status && PLanDetails.plan_status){
      navigate("/chef-list")
    }else{
      navigate("/subscription-plans")
    }
  
   
  }



  return loading ? (
    <Spinner />
  ) : (
    <>
      <DynamicDialog
        openDialog={openDialog}
        title={
          !PLanDetails.chef_status && PLanDetails.plan_status
            ? `${PLanDetails.plan_name} \nYour Chef is not added. Please add a chef`
            : " No Subscription plan selected"
        }
        buttonText={"+ Add Subscription"}
        showSecondButton={false}
        changeScreen={() => changeScreen()}
        closeDialog={(value) => {
          setOpenDialog(value);
        }}
      />

      <LoggedNav customer={props.customer} />
      <div className="container my-5 ">
        {isMobile ? null : (
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item active"
                style={{ cursor: "pointer" }}
                aria-current="page"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <p>
                <span className="pe-2 pb-1 h4">
                  <RiArrowLeftSLine className="mt-1" />
                </span>
                <span >  Go Back</span>
                </p>
             
              
              </li>
            </ol>
          </nav>
        )}
        <MobileUpdateModal />
        <AddressUpdateModal customerAddress={customer} />
        <EmailUpdateModal />
        <div className="d-flex justify-content-center align-items-center">
          <img
            id="profile_pic"
            src={
              customer.customer_profile_image
                ? customer.customer_profile_image
                : profile
            }
            className=""
            alt=""
            style={{ borderRadius: "50%", height: "80px", width: "80px" }}
          />
        </div>
        <div
          className="profile-img"
          style={{
            position: "absolute",
            left: isMobile ? "52%" : "51%",
            top: isMobile ? "175px" : "210px",
          }}
        >
          <div
            className="add-media d-flex align-items-center justify-content-center"
            onClick={handleClick}
            style={{
              borderRadius: "50%",
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
            }}
          >
            <FaPencilAlt />
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              name="profilePic"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="container" style={{ position: "" }}>
          <div className="mt-4">
            <h5
              style={{
                textAlign: "center",
                marginTop: "10px",
                fontWeight: 900,
              }}
            >
              {customer.customer_name}
            </h5>
            <div className="row my-5">
              <div className="col-sm m-1">
                <div
                  className="border border-1 p-2"
                  style={{
                    borderRadius: "20px",
                    boxShadow: "0,0,0,rgba(0,0,0,0.5)",
                  }}
                >
                  <div className="px-2 d-flex justify-content-between">
                    <label
                      htmlFor="Input"
                      className="form-label"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Registered Email
                    </label>
                    <button
                      className="pe-2 h6"
                      type="button"
                      data-bs-toggle="modal"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      data-bs-target="#emailUpdate"
                    >
                      <RiPencilLine />
                    </button>
                  </div>
                  <h6 className="px-2 text-wrap">{customer.customer_email}</h6>
                  <input
                    type="email"
                    className="form-control shadow-none d-none"
                    placeholder={customer.customer_email}
                    aria-label="City"
                  />
                </div>
              </div>
              <div className="col-sm m-1">
                <div
                  className="border border-1 p-2"
                  style={{
                    borderRadius: "20px",
                    boxShadow: "0,0,0,rgba(0,0,0,0.5)",
                  }}
                >
                  <div className="px-2 d-flex justify-content-between">
                    <label
                      htmlFor="Input"
                      className="form-label"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Registered Phone number
                    </label>
                    <button
                      className="pe-2 h6"
                      type="button"
                      data-bs-toggle="modal"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      data-bs-target="#mobileUpdate"
                    >
                      <RiPencilLine />
                    </button>
                  </div>
                  <h6 className="px-2 text-wrap">{customer.customer_mobile}</h6>
                  <input
                    type="number"
                    className="form-control shadow-none d-none"
                    placeholder={customer.customer_mobile}
                    aria-label="State"
                  />
                </div>
              </div>
              <div className="col-sm m-1">
                <div
                  className="border border-1 p-2"
                  style={{
                    borderRadius: "20px",
                    boxShadow: "0,0,0,rgba(0,0,0,0.5)",
                  }}
                >
                  <div className="px-2 d-flex justify-content-between">
                    <label
                      htmlFor="Input"
                      className="form-label"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Saved Address
                    </label>
                    <button
                      className="pe-2 h6"
                      type="button"
                      data-bs-toggle="modal"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      data-bs-target="#addressUpdate"
                    >
                      <RiPencilLine />
                    </button>
                  </div>
                  <h6 className="px-2 text-wrap">
                    {customer.customer_address?.replaceAll(",", ", ")}
                  </h6>
                  <input
                    type="text"
                    className="form-control shadow-none d-none"
                    placeholder={customer.customer_address}
                    aria-label="Zip"
                  />
                </div>
              </div>
            </div>

            <div
              className="mx-auto"
              style={{ marginTop: "35px", width: isMobile ? "100%" : "400px" }}
            >
           <Link
  to={(PLanDetails.chef_status && PLanDetails.plan_status) ?"#":  "/chef-list" }
  className={`border border-1 my-2  d-flex justify-content-between align-items-center ${
    (PLanDetails.chef_status && PLanDetails.plan_status) ? "disabled-link" : ""
  }`}
  style={{
    borderRadius: "20px",
    background: (PLanDetails.chef_status && PLanDetails.plan_status) ? "#f0f0f0" : "",
    textDecoration: "none",
    padding: "10px 30px",
    color: (PLanDetails.chef_status && PLanDetails.plan_status) ? "gray": "black",
    fontWeight: 600,
    pointerEvents:   (PLanDetails.chef_status && PLanDetails.plan_status)  ? "none":  "auto",
    // PLanDetails.one_time_booking !== 1
  }}
>
  Browse Chef <RiArrowRightLine />
</Link>

              <Link
                to="/order-list"
                className="border border-1 my-2 text-dark d-flex justify-content-between align-items-center"
                style={{
                  borderRadius: "20px",
                  background: "none",
                  textDecoration: "none",
                  padding: "10px 30px",
                  color: "black",
                  fontWeight: 600,
                  boxShadow: "0,0,0,rgba(0,0,0,0.5)",
                }}
              >
                Order History <RiArrowRightLine />
              </Link>
              <div
                onClick={() => mySubcriptionDetialCheck()}
                className="border border-1 my-2 text-dark d-flex justify-content-between align-items-center"
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#FF5E41",
                  textDecoration: "none",
                  padding: "10px 30px",
                  color: "black",
                  fontWeight: 600,
                  boxShadow: "0,0,0,rgba(0,0,0,0.5)",
                }}
              >
                My Subcription Plans <RiArrowRightLine />
              </div>
              {/* <Link
                to="/update-card-details"
                className="border border-1 my-2 text-dark d-flex justify-content-between align-items-center"
                style={{
                  borderRadius: "20px",
                  background: "none",
                  textDecoration: "none",
                  padding: "10px 30px",
                  color: "black",
                  fontWeight: 600,
                  boxShadow: "0,0,0,rgba(0,0,0,0.5)",
                }}
              >
                Update Credit Card <RiArrowRightLine />
              </Link> */}
              <a
                type="button"
                onClick={() => {
                  localStorage.clear();
                  navigate("/sign-in");
                }}
                className="border border-1 my-2 text-dark d-flex justify-content-between align-items-center"
                style={{
                  borderRadius: "20px",
                  background: "none",
                  textDecoration: "none",
                  padding: "10px 30px",
                  color: "black",
                  fontWeight: 600,
                  boxShadow: "0,0,0,rgba(0,0,0,0.5)",
                }}
              >
                Logout <RiArrowRightLine />
              </a>
              <a
                href="mailto:support@chefrepublic.us"
                className="text-center mt-2 d-flex justify-content-center align-items-center"
                style={{
                  border: "0px",
                  textDecoration: "none",
                  color: "#FF5E41",
                }}
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
