// import React, { useState, useEffect } from "react";
// import Spinner from "./BePatient";
// import { useNavigate } from "react-router-dom";
// import LoggedNav from "../components/LoggedNav";
// import ContactUsPop from "../components/ContactUsPop";
// import OrderItemChef from "../components/OrderItemChef";
// import SubcriptionList from "../components/SubcriptionList";
// import { isMobile } from "react-device-detect";

// import { Link } from "react-router-dom";

// function SubscriptionListChef(props) {

//   const [activeButton, setActiveButton] = useState("orders"); // State to track active button
//   const [orders, setOrders] = useState([]);
//   const [subcription, setsubcription] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   const updateOrders = async () => {
//     let data  = await fetch(
//       `${process.env.REACT_APP_BASE_URL}book/chef_booking_history`,
//       {
//         method: "POST",
//          headers: {
//           "Authorization": "Bearer " + localStorage.getItem("token"),
//           "Content-Type": "application/json",
//         },
//          body: JSON.stringify({
//           "email":`${localStorage.getItem("owner")}`,
//           "user_id":`${localStorage.getItem("chef_id")}`,                   
//          })
                                      
//       }
//     );
//     let parsedData = await data.json();
//     console.log(parsedData);
//     setOrders(parsedData.data);
//     setLoading(false);
//   console.log({
//             "email":`${localStorage.getItem("owner")}`,
//             "user_id":`${localStorage.getItem("chef_id")}`,                   
//            });
//   };
//   const getSubcriptionData = async () => {
//     let data  = await fetch(
//       `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_for_chef`,
//       {
//         method: "POST",
//    headers: {
//           "Authorization": "Bearer " + localStorage.getItem("token"),
//           "Content-Type": "application/json",
//         },
//          body: JSON.stringify({
//           "chef_id":`${localStorage.getItem("chef_id")}`,                   
//          })
                                      
//       }
//     );
//     let parsedData = await data.json();
//     console.log(parsedData);
//     setsubcription(parsedData.data);
//     setLoading(false);
//   console.log({
//             "email":`${localStorage.getItem("owner")}`,
//             "user_id":`${localStorage.getItem("chef_id")}`,                   
//            });
//   };
//   const getStatusList = async () => {
//     let data = await fetch(
//       `${process.env.REACT_APP_BASE_URL}status/all_status`
//     );
//     let parsedData = await data.json();
//     for (let index = 0; index < parsedData.data.length; index++) {
//       const element = parsedData.data[index];
//       localStorage.setItem(element.name, element.color)
//     }
//   };
//   const routeTosignIn = async () => {
//     if((localStorage.getItem("role"))){
//       console.log('display Play List');
//     }else{
//       navigate("/sign-in");
//     }
//   };
  

//   let role = localStorage.getItem("role");
//   let token = localStorage.getItem("token");
//   useEffect(() => {

//     var title = `Order History | CHEF | CHEF REPUBLIC`;
//     var desc = "Review Orders you have Meal Prepped for customers of Chef Republic"
//     const titleTag = document.querySelector('title');
//     titleTag.innerText = title;
//     const metaTitle = document.querySelector("meta[name='title']");
//     metaTitle.setAttribute('content',title)
//     const metaDescription = document.querySelector("meta[name='description']");
//     metaDescription.setAttribute('content',desc)
//     const metaTitleOG = document.querySelector("meta[property='og:title']");
//     metaTitleOG.setAttribute('content',title)
//     const metaDescriptionOG = document.querySelector("meta[property='og:description']");
//     metaDescriptionOG.setAttribute('content',desc)
//     const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
//     metaTitleTwitter.setAttribute('content',title)
//     const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
//     metaDescriptionTwitter.setAttribute('content',desc)

//     routeTosignIn();
//     localStorage.removeItem("from_msg")
//     getStatusList();
//     updateOrders();
//     getSubcriptionData();
//     // eslint-disable-next-line
//   }, [activeButton]);
//   return (
//     <>
//       {loading ? (
//         <Spinner />
//       ) : (
//         <>
//           <LoggedNav customer={props.customer} main={true} />
//           <div className="container my-5">
//             <div className="text-center mb-4">
//               <h1 className="fw-bold text-center fs-2">
//                 {"Subscription History"}
//               </h1>
//               <div className="text-center mt-3">
//                 <Link
//                   className={`${"btn-white"} mx-2`}
//                   to="/chef/order-list"
//                   style={{
//                     width: isMobile ? "165px" : "200px",
//                     display: "inline-block",
//                     textAlign: "center",
//                   }}
//                 >
//                   Order
//                 </Link>

//                 <Link
//                   className={`${ "order_details"} mx-2`}
//                   to="/subscription-list"

//                   style={{
//                     width: isMobile ? "165px" : "200px",
//                     display: "inline-block",
//                     textAlign: "center",
//                   }}
//                 >
//                   Subscription
//                 </Link>
//               </div>
//             </div>

//             <div className="row">
//               {subcription && subcription.length > 0 ? (
//                 subcription.map((content, index) => (
//                   <SubcriptionList key={index} content={content} />
//                 ))
//               ) : (
//                 <h4 className="py-3 text-center fs-6">
//                   You have no subscriptions yet. Stay tuned!
//                 </h4>
//               )}
//             </div>
//           </div>
//         </>
//       )}
//     </>
//   );
// }

// export default SubscriptionListChef;

import React, { useState, useEffect } from "react";
import Spinner from "./BePatient";
import { useNavigate } from "react-router-dom";
import LoggedNav from "../components/LoggedNav";
import SubcriptionList from "../components/SubcriptionList";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

// Utility function with null check
const titleCase = (str) => {
  if (!str) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

function SubscriptionListChef(props) {
  const [activeButton] = useState("subscriptions");
  const [orders, setOrders] = useState([]);
  const [subscription, setSubscription] = useState([]); // Fixed variable name
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const updateOrders = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}book/chef_booking_history`,
        {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: localStorage.getItem("owner"),
            user_id: localStorage.getItem("chef_id"),                   
          })
        }
      );
      const parsedData = await response.json();
      setOrders(parsedData.data || []);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setOrders([]);
    }
  };

  const getSubscriptionData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_for_chef`,
        {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chef_id: localStorage.getItem("chef_id"),                   
          })
        }
      );
      const parsedData = await response.json();
      setSubscription(parsedData.data || []);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      setSubscription([]);
    } finally {
      setLoading(false);
    }
  };

  const getStatusList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}status/all_status`
      );
      const parsedData = await response.json();
      parsedData.data?.forEach(status => {
        localStorage.setItem(status.name, status.color);
      });
    } catch (error) {
      console.error("Error fetching status list:", error);
    }
  };

  const routeToSignIn = () => {
    if (!localStorage.getItem("role")) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    // SEO Meta Tags
    const metaTags = {
      title: "Subscription History | CHEF | CHEF REPUBLIC",
      description: "Review subscriptions you have with customers of Chef Republic"
    };

    document.title = metaTags.title;
    document.querySelector('meta[name="description"]')?.setAttribute("content", metaTags.description);
    document.querySelector('meta[property="og:title"]')?.setAttribute("content", metaTags.title);
    document.querySelector('meta[property="og:description"]')?.setAttribute("content", metaTags.description);
    document.querySelector('meta[property="twitter:title"]')?.setAttribute("content", metaTags.title);
    document.querySelector('meta[property="twitter:description"]')?.setAttribute("content", metaTags.description);

    routeToSignIn();
    localStorage.removeItem("from_msg");
    getStatusList();
    updateOrders();
    getSubscriptionData();
  }, [activeButton]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
   <LoggedNav customer={props.customer} main={true} />
              <div className="container my-5">
                <div className="text-center mb-4">
                  <h1 className="fw-bold text-center fs-2">
                    {"Subscription History"}
                  </h1>
                  <div className="text-center mt-3">
                    <Link
                      className={`${"btn-white"} mx-2`}
                      to="/chef/order-list"
                      style={{
                        width: isMobile ? "165px" : "200px",
                        display: "inline-block",
                        textAlign: "center",
                      }}
                    >
                      Order
                    </Link>
    
                    <Link
                      className={`${ "order_details"} mx-2`}
                      to="/subscription-list"
    
                      style={{
                        width: isMobile ? "165px" : "200px",
                        display: "inline-block",
                        textAlign: "center",
                      }}
                    >
                      Subscription
                    </Link>
                  </div>
                </div>
    
          
        <div className="row g-4">
          {subscription.length > 0 ? (
            subscription.map((content, index) => (
              <SubcriptionList 
                key={index} 
                content={{
                  ...content,
                  status: content.status || "Unknown Status", // Default value
                  customer_name: content.customer_name || "Anonymous Customer"
                }}
                titleCase={titleCase}
              />
            ))
          ) : (
            <div className="col-12">
              <h4 className="py-3 text-center fs-6 text-muted">
              You have no subscriptions yet. Stay tuned!
                {/* No active subscriptions found. New subscriptions will appear here when customers subscribe to your meal plans. */}
              </h4>
            </div>
          )}
        </div>
              </div>




      {/* <LoggedNav customer={props.customer} main={true} />
      <div className="container my-5">
        <div className="text-center mb-4">
          <h1 className="fw-bold text-center fs-2">
            Subscription History
          </h1>
          <div className="d-flex justify-content-center gap-3 mt-3">
            <Link
              to="/chef/order-list"
              className={`btn ${activeButton === "orders" ? "btn-primary" : "btn-outline-primary"}`}
              style={{ minWidth: isMobile ? "140px" : "160px" }}
            >
              Orders
            </Link>
            <Link
              to="/subscription-list"
              className={`btn ${activeButton === "subscriptions" ? "btn-primary" : "btn-outline-primary"}`}
              style={{ minWidth: isMobile ? "140px" : "160px" }}
            >
              Subscriptions
            </Link>
          </div>
        </div>

        <div className="row g-4">
          {subscription.length > 0 ? (
            subscription.map((content, index) => (
              <SubcriptionList 
                key={index} 
                content={{
                  ...content,
                  status: content.status || "Unknown Status", // Default value
                  customer_name: content.customer_name || "Anonymous Customer"
                }}
                titleCase={titleCase}
              />
            ))
          ) : (
            <div className="col-12">
              <h4 className="py-3 text-center fs-6 text-muted">
              You have no subscriptions yet. Stay tuned!
                 No active subscriptions found. New subscriptions will appear here when customers subscribe to your meal plans. 
              </h4>
            </div>
          )}
        </div>
      </div> */}
    </>





  );
}

export default SubscriptionListChef;