import './index.css';

import App from './App';
import React from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import ScrollToTop from './helpers/ScrollToTop';
import { Toaster } from 'react-hot-toast';
import { createRoot } from "react-dom/client";
import reportWebVitals from './reportWebVitals';

// Check if deployment status has changed
const currentBuildVersion = '1.0.2'; // Update this when deploying new version
try {
  const storedBuildVersion = localStorage.getItem('buildVersion');
  
  // If the version has changed or doesn't exist yet, clear storage
  if (!storedBuildVersion || storedBuildVersion !== currentBuildVersion) {
    localStorage.clear();
    sessionStorage.clear();
    console.log('Storage cleared due to build version change');
    // Store the current version after clearing
    localStorage.setItem('buildVersion', currentBuildVersion);
  }
} catch (error) {
  console.error('Error handling storage:', error);
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router basename='/'>
    <ScrollToTop />
    <Toaster />
    <App />
  </Router>
);
reportWebVitals();



// sudo scp -r ./build/* /var/www/build/
