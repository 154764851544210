import "../CSS File/SubscriptionSummary.css";

import React, {useEffect, useState} from 'react';

import DynamicDialog from './DynamicDialog';
import { IoMdStopwatch } from "react-icons/io";
import { MdOutlineAttachMoney } from "react-icons/md";
import { TfiTimer } from "react-icons/tfi";
import chatBubble from "../images/icons/chatBubble.png";
import profile from '../images/Vector.jpg';
import smile from "../images/smile.svg"
import { useNavigate } from 'react-router-dom';

const SubscriptionSummary = (props) => {
debugger

  // Pricing is as per the Service Address Zip Code  red color

  const [content, setdPlans] = useState([]);
  const [postalCode, setPostalCode] = useState(null);
  const [address, setAddress] = useState(null);
    const [PlanList, setPlans] = useState([]);
    const [PLanDetails,SetPlanData]=useState([]);
    const [planName,setPlanName]=useState("");
    const [openaddDialog, setOpenaddDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [imageDialog, setimageDialog] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isApiSuccess, setIsApiSuccess] = useState(false); // Track if API call succeeded
    const [loading, setLoading] = useState(true);

    
    const handleButtonClick = (Option, chefOption) => {
      
      EnterOrderDetail(chefOption.subscribe_chef_id);
     
    };

    const AddSubscription = () => {
      navigate("/subscription-plans");
    };

  let navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          UserDetails(),
          routeTosignIn(),
          checksubcription(),
          SubscriptionPlan()
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [planName]);



  
    const checksubcription = async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: `${localStorage.getItem("logged_user_id")}`,
                "postal_code": `${localStorage.getItem("postalCode")}`
            }),
          }
        );
        const res_json = await response.text();
        const CurrentPlan = JSON.parse(res_json);

        if (CurrentPlan.status === true) {
          console.log(CurrentPlan);
          SetPlanData(CurrentPlan);
          setIsApiSuccess(true);
        } else {
          setIsApiSuccess(false); // Handle error
          console.error("Error: Response status is false");
        }
      } catch (error) {
        console.error("Error while fetching data, please try later:", error);
      }
    };
    let SubscriptionPlan = async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_chef`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: `${localStorage.getItem("logged_user_id")}`,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        let responseData = await response.json();
        console.log(responseData);
        if (responseData) {
          console.log(responseData);
          setPlans(responseData);
          setIsApiSuccess(true);
        } else {
          setIsApiSuccess(false); 
          console.error("Error: Response status is false");
        }
      } 
      catch (error) {
        console.error("Error fetching subscription data:", error);
      }
     
     
    };
  
 const UserDetails = async () => {
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}user/loggedin_customer_detail`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      const res_json = await response.text();
      const userDetails = JSON.parse(res_json);

      if (userDetails.status === true) {
        
        const fetchedPostalCode = userDetails.data[0].postal_code; // Extract and store postalcode
        const fetchedAddress = userDetails.data[0].customer_address; // Extract and store address

        console.log("Postal Code:", fetchedPostalCode);
        console.log("Address:", fetchedAddress);

        // Store in state variables
        setPostalCode(fetchedPostalCode);
        setAddress(fetchedAddress);
        setIsApiSuccess(true);
      } else {
        setIsApiSuccess(false);
        console.error("Error: Response status is false");
      }
    } catch (error) {
      console.error("Error while fetching data, please try later:", error);
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/cancel_subscription_plan`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: localStorage.getItem("logged_user_id"),
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res_json = await response.text();
      const data = JSON.parse(res_json);

      if (data.status === true) {
        // Perform actions after successful subscription cancellation
        localStorage.removeItem("subscription_id");
        // Store postalCode and address in localStorage
        if (postalCode) localStorage.setItem("postalCode", postalCode);
        if (address) localStorage.setItem("address", address);

        setPlanName(data.messages); // Set the plan name
        console.log("Subscription canceled successfully!");
      } else {
        console.error("Error while canceling the subscription.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
    let cancelConfirmationSubscription = async () => {
      setOpenDialog(!openDialog);
    };

  
    

    const changeScreen = () => {
      cancelSubscription();
      setOpenDialog(false);
      setPlanName("");
      setimageDialog(true);
    };
    const changeScreenSecondButton = () => {
      navigate("/subscription-plans");
    };

    const handleNavigate = () => {
      navigate("/chef-list");
    };

    const EnterOrderDetail = (chef_id) => {
      localStorage.setItem("chef_id", chef_id);
      navigate("/booking-detail");
    };

    const routeTosignIn = async () => {
      if((localStorage.getItem("role"))){
        console.log('display Play List');
      }else{
        navigate("/sign-in");
      }
    };
  
    return (
      <>
        <DynamicDialog
          openDialog={openaddDialog}
          title={
            !PLanDetails.chef_status && PLanDetails.plan_status
              ? `${PLanDetails.plan_name} \nYour Chef is not added. Please add a chef`
              : "Do you want to view and add a new subscription plan?"
          }
          buttonText={"Yes"}
          showSecondButton={false}
          changeScreen={() => AddSubscription()}
          closeDialog={(value) => {
            setOpenaddDialog(value);
          }}
        />

        {imageDialog ? (
          <DynamicDialog
            getDialog={1}
            imagesrc={smile}
            openDialog={!openDialog}
            title={planName}
            // Your Subscription Plan Has Been Canceled
            buttonText={"Cancel Subscription"}
            showSecondButton={true}
            changeScreen={() => changeScreen()}
            secondButtonText={"Select Another Plan"}
            changeScreenSecondButton={() => changeScreenSecondButton()}
            closeDialog={(value) => {
              setimageDialog(value);
            }}
          />
        ) : (
          <DynamicDialog
            getDialog={2}
            imagesrc={""}
            openDialog={openDialog}
            title={"Do you want to cancel subscription?"}
            buttonText={"Yes"}
            showSecondButton={true}
            changeScreen={() => changeScreen()}
            secondButtonText={"No"}
            changeScreenSecondButton={() => setOpenDialog(!openDialog)}
            closeDialog={(value) => {
              setOpenDialog(value);
            }}
          />
        )}

        {
          <div className="container">
            <p className="mt-2 mb-0 text-center">Subscription Summary</p>

            {loading ? (
              <div className="text-center mt-5 py-5">
                <div className="spinner-border text-orange" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-2">Loading subscriptions...</p>
              </div>
            ) : PlanList.length > 0 ? (
              <>
                <div className="mobile new-design-card mt-3">
                  <div
                    style={{
                      display: !PLanDetails.chef_status ? "block" : "none",
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "5px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p
                        role="button"
                        style={{
                          whiteSpace: "pre-wrap",
                          textAlign: "center",
                          marginTop: "7px",
                          marginBottom: "3px",
                          marginRight: "6px",
                          color: "#ff5e41",
                          cursor: "pointer",
                          fontSize: "0.9rem",
                        }}
                        onClick={() => AddSubscription()}
                      >
                        + Select Pricing
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    {PlanList.map((option, index) => (
                      <div className="col-md-4 col-sm-12" key={index}>
                        <div
                          className="border row border-1 m-1 py-2"
                          style={{
                            borderRadius: "20px",
                            boxShadow: "0 0 0 rgba(0,0,0,0.2)", // Corrected boxShadow syntax
                          }}
                        >
                          <div className="row">
                            <div>
                              <span
                                className="badge text-wrap"
                                style={{
                                  borderRadius: "20px",
                                  color: "white",
                                  backgroundColor:
                                    option.status === "Active"
                                      ? "#4dff4d"
                                      : "red", // Simplified the static backgroundColor
                                }}
                              >
                                {option.status}
                              </span>

                              <span
                                className="badge text-wrap"
                                style={{
                                  borderRadius: "20px",
                                  marginLeft: "10px",
                                  color: "white",
                                  backgroundColor: `${option.color_code}`,
                                }}
                              >
                                {option.plan_name}
                              </span>
                            </div>

                            <div className="row my-2">
                              <p className="pl-4 mb-0">
                                Subscription ID: {option.subscription_no}
                              </p>
                            </div>
                            {option.chef.map((chefData, ChefIndex) => (
                              <div className="chef-new-card" key={ChefIndex}>
                                <div className="row">
                                  <div className="col-md-2 col-2">
                                    <div
                                      className="d-flex justify-content-center align-items-center my-2"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      <img
                                        src={
                                          chefData.booking_by_user_profile_image
                                            ? chefData.booking_by_user_profile_image
                                            : profile
                                        }
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                        alt="Profile"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-10 col-10 d-flex align-items-center">
                                    <h6 className="fw-bold">
                                      {chefData.chef_name}
                                    </h6>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 col-2">
                                    <button
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        border: "1px solid #FF5E41",
                                        cursor: "pointer",
                                        backgroundColor: "#FF5E41",
                                      }}
                                      className="px-1"
                                      onClick={() => {
                                        console.log(option.chef.length);
                                        navigate("/subscription-message", {
                                          state: {
                                            data: {
                                              plan_id: option.plan_id,
                                              plan_name: option.plan_name,
                                              color_code: option.color_code,
                                              image: chefData.profile_image,
                                              name: chefData.chef_name,
                                              subscription_status:
                                                chefData.status,
                                              customer_id: chefData.user_id,
                                              chef_id:
                                                chefData.subscribe_chef_id,
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={chatBubble}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                  <div className="col-md-10 col-10">
                                    <div className="row my-2">
                                      <div className="col-1">
                                        <p className="p-0 mb-0">
                                          <IoMdStopwatch
                                            style={{
                                              fontSize: "1.2rem",
                                            }}
                                          />
                                        </p>
                                      </div>
                                      <div className="col-10">
                                        <p
                                          className="p-0 m-0"
                                          style={{ fontSize: "0.9rem" }}
                                        >
                                          Every {chefData.timeslot}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row my-2">
                                      <div className="col-1">
                                        <p className="p-0 mb-0">
                                          <MdOutlineAttachMoney
                                            style={{ fontSize: "1.2rem" }}
                                          />
                                        </p>
                                      </div>
                                      <div className="col-10 text-break">
                                        <p
                                          className="mb-0"
                                          style={{
                                            fontSize: "0.9rem",
                                            paddingTop: "0.1rem",
                                          }}
                                        >
                                          {chefData.price}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="button-container">
                                  <div style={{ position: "relative" }}>
                                    <button
                                      className="px-1 container-button"
                                      style={{
                                        width: "140px",
                                        fontSize: "0.7rem",
                                        pointerEvents:
                                        option?.enter_order_details === true
                                          ? false // Enabled when true
                                          : true // Disabled when null, undefined, or false
                                          ? "none"
                                          : "auto",
                                      opacity:
                                        option?.enter_order_details === true
                                          ? false // Enabled when true
                                          : true // Disabled when null, undefined, or false
                                          ? 0.6
                                          : 1,
                                      }}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "subscription_id",
                                          chefData.subscription_id
                                        );
                                        handleButtonClick(
                                          option.chef,
                                          chefData
                                        );
                                      }}
                                      disabled={
                                        option?.enter_order_details === true
                                          ? false // Enabled when true
                                          : true // Disabled when null, undefined, or false
                                      }

                                    
                                    >
                                      Enter Order Details
                                    </button>
                                  </div>

                    
                                </div>
                                {ChefIndex < option.chef.length - 1 && (
                                  <hr style={{ marginBottom: "5px" }} />
                                )}
                              </div>
                            ))}
                            {option.status === "Active" &&
                              option.max_dates > option.chef.length && (
                                <div className="select one more day text-center">
                                  <p
                                    role="button"
                                    onClick={() => handleNavigate()}
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      textAlign: "center",
                                      marginTop: "7px",
                                      marginBottom: "3px",
                                      marginRight: "6px",
                                      color: "#ff5e41",
                                      cursor: "pointer",
                                      fontSize: "0.7rem",
                                    }}
                                  >
                                    + Select One More Day For The Week
                                  </p>
                               
                                </div>

                              )}
                          </div>
                        </div>

                        <div>
                          {option.status === "Active" &&
                            option.min_dates > option.chef.length && (
                              <div
                                style={{
                                  color: "#FF5E41",
                                  marginBottom: "5px",
                                  marginLeft: "10px",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ fontSize: "0.55rem" }}>
                                  {option.message}{" "}
                                </span>
                              </div>
                            )}
                        </div>

                        <div
                          style={{
                            paddingTop: "5px",
                            textAlign: "center",
                            display:
                              option.status === "Active" ? " block" : "none",
                          }}
                        >
                          <button
                            className="px-1 container-button mb-3"
                            style={{ width: "150px", fontSize: "0.7rem" }}
                            onClick={() => cancelConfirmationSubscription()}
                            disabled={
                              option.status === "Inactive" ||
                              option.min_dates > option.chef.length
                            }
                          >
                            Cancel Subscription
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="App text-start">
                  <div
                    style={{
                      display: !PLanDetails.chef_status ? "block" : "none",
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "5px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="px-1 container-button"
                        style={{ width: "140px", fontSize: "0.7rem" }}
                        onClick={() => AddSubscription()}
                      >
                        Select Pricing
                      </button>
                    </div>
                  </div>

                  {isApiSuccess &&
                    PlanList.length > 0 &&
                    PlanList.map((option, index) => (
                      <div key={index} className="row my-2 ">
                        <div
                          className="p-3 "
                          style={{
                            borderRadius: "20px",
                            border: "1px solid #dee2e6",
                          }}
                        >
                          <div className="mb-3">
                            <span style={{ marginRight: "20px" }}>
                              Subscription ID: {option.subscription_no}{" "}
                            </span>
                            <span
                              className="badge "
                              style={{
                                borderRadius: "20px",
                                color: "white",
                                backgroundColor:
                                  option.status === "Active"
                                    ? "#4dff4d"
                                    : "red", // Simplified the static backgroundColor
                              }}
                            >
                              {option.status}
                            </span>
                            <span
                              className="badge"
                              style={{
                                borderRadius: "20px",
                                marginLeft: "10px",
                                color: "white",
                                backgroundColor: `${option.color_code}`, // Simplified the static backgroundColor
                              }}
                            >
                              {option.plan_name}
                            </span>
                          </div>

                          {option.chef.map((chefOption, chefIndex) => (
                            <div
                              key={chefIndex}
                              className="row me-2 my-2"
                              style={{
                                justifyContent: "start",
                                display: "flex",
                              }}
                            >
                              <div
                                className="col-md-3"
                                style={{ borderRight: "2px solid black" }}
                              >
                                <div className="chef-name">
                                  <img
                                    src={
                                      chefOption.booking_by_user_profile_image
                                        ? chefOption.booking_by_user_profile_image
                                        : profile
                                    }
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                      marginRight: "5px",
                                    }}
                                    alt="Profile"
                                  />
                                  <span>{chefOption.chef_name}</span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="chef-time mt-2">
                                  <TfiTimer size={20} />
                                  <span className="ms-2">
                                    Every {chefOption.timeslot}{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div
                                  className="row chef-button"
                                  style={{ borderRight: "2px solid black" }}
                                >
                                  <div className="col-md-10 text-center">
                                    <button
                                      className="px-1 container-button"
                                      style={{
                                        width: "140px",
                                        fontSize: "0.7rem",

                                        pointerEvents:
                                          option?.enter_order_details === true
                                            ? false // Enabled when true
                                            : true // Disabled when null, undefined, or false
                                            ? "none"
                                            : "auto",
                                        opacity:
                                          option?.enter_order_details === true
                                            ? false // Enabled when true
                                            : true // Disabled when null, undefined, or false
                                            ? 0.6
                                            : 1,
                                      }}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "subscription_id",
                                          chefOption.subscription_id
                                        );
                                        handleButtonClick(
                                          option.chef,
                                          chefOption
                                        );
                                      }}
                                      disabled={
                                        option?.enter_order_details === true
                                          ? false // Enabled when true
                                          : true // Disabled when null, undefined, or false
                                      }
                                    >
                                      Enter Order Details
                                    </button>
                                  </div>
                       
                                  <div className="col-md-2">
                                    <button
                                      className="px-1 container-button"
                                      onClick={() => {
                                        console.log(option.chef.length);
                                        navigate("/subscription-message", {
                                          state: {
                                            data: {
                                              // status: option,
                                              plan_id: option.plan_id,
                                              plan_name: option.plan_name,
                                              color_code: option.color_code,
                                              image: chefOption.profile_image,
                                              name: chefOption.chef_name,
                                              subscription_status:
                                                chefOption.status,
                                              customer_id: chefOption.user_id,
                                              chef_id:
                                                chefOption.subscribe_chef_id,
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={chatBubble}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div
                                  className="px-1 mt-2"
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#000000",
                                  }}
                                >
                                  ${option.price}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="below_button">
                            {option.status === "Active" &&
                              option.max_dates > option.chef.length && (
                                <div className="row text-center">
                                  <div className="mb-3">
                                    <button
                                      className="px-2 container-button"
                                      style={{ fontSize: "0.7rem" }}
                                      onMouseEnter={() => setIsHovered(true)}
                                      onMouseLeave={() => setIsHovered(false)}
                                      onClick={() => handleNavigate()}
                                    >
                                      {isHovered ? (
                                        <span style={{ fontSize: "0.7rem" }}>
                                          + Select One More Day For The Week
                                        </span>
                                      ) : (
                                        <span
                                          className="px-3"
                                          style={{ fontSize: "0.8rem" }}
                                        >
                                          +
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div>
                          {option.status === "Active" &&
                            option.min_dates > option.chef.length && (
                              <div
                                style={{
                                  color: "#FF5E41",
                                  marginBottom: "5px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "0.7rem",
                                  }}
                                >
                                  {option.message}{" "}
                                </span>
                              </div>
                            )}
                        </div>
                        <div
                          style={{
                            paddingTop: "5px",
                            display:
                              option.status === "Active" ? " block" : "none",
                          }}
                        >
                          <button
                            className="px-1 container-button"
                            style={{ width: "140px", fontSize: "0.7rem" }}
                            onClick={() => cancelConfirmationSubscription()}
                          >
                            Cancel Subscription
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    paddingTop: "5px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="px-1 container-button"
                    style={{ width: "140px", fontSize: "0.7rem" }}
                    onClick={() => AddSubscription()}
                  >
                    Select Pricing
                  </button>
                </div>
                <div className="text-center mt-5">
                  <p>You Do Not Have Any Subscriptions Yet</p>{" "}
                  {/* Show if no plans after successful API */}
                </div>
              </>
            )}
          </div>
        }
      </>
    );
  }
  
  export default SubscriptionSummary;
